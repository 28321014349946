import React from 'react';

export function MessageIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.666 2.00001C9.279 1.99501 6.156 3.82801 4.509 6.78801C2.862 9.74801 2.951 13.369 4.741 16.244L3 21L7.755 19.259C11.119 21.344 15.433 21.08 18.517 18.599C21.601 16.118 22.784 11.961 21.468 8.22801C20.153 4.49401 16.624 1.99801 12.666 2.00001V2.00001Z"
        stroke="#19181B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MagnifyingGlassIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.0002 19.0002L15.3125 15.3125" stroke="#62606B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M1 9.379C1 14.0066 4.75141 17.758 9.379 17.758C14.0066 17.758 17.758 14.0066 17.758 9.379C17.758 4.75141 14.0066 1 9.379 1V1C4.75155 1.00034 1.00034 4.75155 1 9.379"
        stroke="#62606B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowLeftIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.25 15H23.75" stroke="#7754D6" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 8.75L6.25 15" stroke="#7754D6" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 21.25L6.25 15" stroke="#7754D6" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function DotsHorizontalIcon() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.625 7.5C3.625 8.12132 3.12132 8.625 2.5 8.625C1.87868 8.625 1.375 8.12132 1.375 7.5C1.375 6.87868 1.87868 6.375 2.5 6.375C3.12132 6.375 3.625 6.87868 3.625 7.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM12.5 8.625C13.1213 8.625 13.625 8.12132 13.625 7.5C13.625 6.87868 13.1213 6.375 12.5 6.375C11.8787 6.375 11.375 6.87868 11.375 7.5C11.375 8.12132 11.8787 8.625 12.5 8.625Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
