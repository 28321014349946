import { IUserMetadata } from '@shared/models/user-metadata.model';
import clsx from 'clsx';
import { toNumber } from 'lodash';
import React from 'react';

export const AVATAR_COLOR_CLASSNAMES = [
  'tw-bg-[#26A0F2] tw-text-[#06314E]',
  'tw-bg-[#FF5B5B] tw-text-[#3D2626]',
  'tw-bg-[#FD9E63] tw-text-[#783307]',
  'tw-bg-[#FFC515] tw-text-[#674E04]',
  'tw-bg-[#6ECB30] tw-text-[#23430E]',
  'tw-bg-[#4A1EE7] tw-text-[#D4CAF8]',
  'tw-bg-[#CD61FF] tw-text-[#411754]',
  'tw-bg-[#D42CCC] tw-text-[#1A0519]',
  'tw-bg-[#C50C3D] tw-text-[#FFFFFF]',
  'tw-bg-[#8D9AA1] tw-text-[#03334E]',
  'tw-bg-[#A64D12] tw-text-[#FFFFFF]',
  'tw-bg-[#333333] tw-text-[#FFFFFF]',
];

type ColorIndex = (typeof AVATAR_COLOR_CLASSNAMES)[number];

export function getAvatarInitials(name?: string) {
  if (!name) {
    return '😀';
  }

  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
}

export function getColorClassnameFromString(str: string): ColorIndex {
  // Convert string to a number using character codes
  const numericValue = str.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);

  // Use lodash's toNumber to ensure we have a valid number
  const hash = toNumber(numericValue);

  // Use the hash to index into the colors array
  return AVATAR_COLOR_CLASSNAMES[Math.abs(hash) % AVATAR_COLOR_CLASSNAMES.length];
}

type Props = {
  user: Partial<IUserMetadata>;
  size?: 'sm' | 'md';
  className?: string;
};

function Avatar({ user, size = 'md', className }: Props) {
  if (!user) {
    return null;
  }

  return (
    <span
      data-testid="avatar"
      className={clsx(
        'tw-rounded-full tw-flex tw-items-center tw-justify-center tw-flex-row',
        size === 'sm' && 'tw-w-5 tw-h-5 md:tw-w-7 md:tw-h-7 tw-text-xs',
        size === 'md' && 'tw-w-7 tw-h-7 md:tw-w-9 md:tw-h-9 tw-text-sm',
        getColorClassnameFromString(user.id || ''),
        className
      )}
    >
      <span>{getAvatarInitials(user.user_name)}</span>
    </span>
  );
}

export default Avatar;
