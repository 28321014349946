import { ILab } from '@core/models/lab.model';
import { IOffice } from '@core/models/office.model';
import useIntercomInjector from '@modules/ecps/useIntercom';
import usePopulateUser from '@shared/hooks/usePopulateUser';
import useOfficeLabStore from '@shared/state/office-lab-store';
import posthog from 'posthog-js';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { useShallow } from 'zustand/react/shallow';

import Shell from './shell/Shell';

function ecpLabIdentification(currentLab: ILab, currentOffice: IOffice) {
  posthog.group('ecp-lab', `${currentOffice.id}|${currentLab.id}`, {
    name: `${currentOffice.name}|${currentLab.name}`,
    ecp_id: currentOffice.id,
    lab_id: currentLab.id,
    ecp_name: currentOffice.name,
    lab_name: currentLab.name,
    lab_account_number: currentLab.account_number,
  });
}

export default function ECPAppView() {
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams<{ ecp_id: string; lab_id: string }>();
  const intercomInjector = useIntercomInjector();
  const navigate = useNavigate();
  const { setActiveOfficeLab } = useOfficeLabStore(useShallow((state) => ({ setActiveOfficeLab: state.setActiveOfficeLab })));
  const { data: populatedUser } = usePopulateUser();

  useEffect(() => {
    if (!populatedUser || !isLoading) {
      return;
    }

    // Check to see if current params match the list of offices and labs this user
    // has access to.
    const allOffices = populatedUser.office_metadata;
    const office = allOffices.find((office) => office.id === params.ecp_id);
    const lab = office?.lab_metadata.find((lab) => params.lab_id === lab.id);
    const found = office && lab;

    if (!found) {
      // Navigate user to default account route to kick off default office/lab setting.
      navigate('/account', {
        replace: true,
      });
      return;
    }

    // The params are legit. Do some vendor identification and 🚀
    if (!window.Cypress) {
      ecpLabIdentification(lab, office);
      intercomInjector(office, lab, populatedUser);
      Userpilot.identify(populatedUser.auth0ID, {
        name: populatedUser.user_name,
        email: populatedUser.user_email,
        created_at: populatedUser.user_created_at,
        user_type: populatedUser.user_type,
        company: {
          id: office.id,
          name: office.name,
          ecp_id: office.id,
          ecp_name: office.name,
          lab_id: lab.id,
          lab_name: lab.name,
        },
      });
    }

    setIsLoading(false);
    setActiveOfficeLab(office, lab);
  }, [isLoading, populatedUser, params.ecp_id, params.lab_id]);

  return isLoading ? null : (
    <div className="layout-dashboard-container">
      <Shell />
      <main id="main" className="tw-overflow-auto">
        <Outlet />
      </main>
    </div>
  );
}
