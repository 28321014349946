import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import useUserPilotStore from '@shared/state/userpilot-store';
import AddAdditonalLab from '../navigation/footer/AddAdditonalLab';
import PMSIntegrationMailTo from '../navigation/footer/PMSIntegrationMailTo';
import ReportIssue from '../navigation/footer/ReportIssue';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { SidebarDisplayMode } from './types';
import { mergeClasses } from '../css_utils';

export default function Shell() {
  const { modals, closeModal } = useUserPilotStore();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [displayMode, setDisplayMode] = useState<SidebarDisplayMode>(() => {
    const savedDisplayMode = localStorage.getItem('sidebarDisplayMode') as SidebarDisplayMode;
    if (savedDisplayMode) {
      return savedDisplayMode;
    }
    return 'expanded';
  });

  useEffect(() => {
    localStorage.setItem('sidebarDisplayMode', displayMode);
  }, [displayMode]);

  return (
    <>
      <Navbar sidebarDisplayMode={displayMode} setSidebarDisplayMode={setDisplayMode} setSidebarOpen={setSidebarOpen} />

      <div
        className="md:tw-hidden"
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      >
        <AnimatePresence>
          {sidebarOpen && (
            <motion.div
              key="sidebar"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={{
                hidden: { x: '-100%' },
                visible: { x: 0 },
                exit: { x: '-100%' },
              }}
              transition={{
                type: 'spring',
                bounce: 0.3,
                duration: 0.5,
              }}
              className="tw-fixed tw-top-[56px] tw-bottom-0 tw-left-0 tw-z-[1001] tw-w-[80%] tw-max-w-xs"
            >
              <Sidebar displayMode="expanded" isMobile setSidebarOpen={setSidebarOpen} />
            </motion.div>
          )}

          {sidebarOpen && (
            <motion.div
              key="overlay"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 0.15 },
                exit: { opacity: 0 },
              }}
              transition={{
                type: 'tween',
                duration: 0.25,
              }}
              className="tw-fixed tw-top-[56px] tw-left-0 tw-right-0 tw-bottom-0 tw-z-[1000] tw-bg-primary-500"
              onClick={() => {
                setSidebarOpen(false);
              }}
            />
          )}
        </AnimatePresence>
      </div>

      <div className={mergeClasses('app-side-nav print:tw-hidden print:tw-w-0', displayMode === 'expanded' ? 'tw-w-[13.5rem]' : 'tw-w-20')}>
        <Sidebar displayMode={displayMode} setSidebarOpen={setSidebarOpen} />
      </div>

      <AddAdditonalLab show={modals['addAdditionalLabModal']} onClose={() => closeModal('addAdditionalLabModal')} />
      <PMSIntegrationMailTo show={modals['pmsIntegrationMailToModal']} onClose={() => closeModal('pmsIntegrationMailToModal')} />
      <ReportIssue show={modals['reportIssueModal']} onClose={() => closeModal('reportIssueModal')} />
    </>
  );
}
